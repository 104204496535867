import { Alert, Backdrop, Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import React, { useEffect, useMemo, useState } from 'react';
import './App.css';

interface Banlog {
  id: number;
  user_id: number;
  group_id: string | null;
  guild_id: number;
  nickname: string | null;
  username: string;
  ban_state: 'IN_REVIEW' | 'QUEUED' | 'BANNED' | 'FAILED_BAN';
  text_filter_id: string;
  // fuzzy: boolean;
  created_at: string;
  filter: string;
  filter_type: string;
  location: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b === null || b === undefined || b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (a === null || a === undefined || b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string | null },
    b: { [key in Key]: number | string | null },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof Banlog;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Banlog) => void;
  order: Order;
  orderBy: string;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: true,
    label: 'ID',
    sortable: false
  },
  {
    id: 'user_id',
    numeric: true,
    label: 'USER_ID',
    sortable: false
  },
  {
    id: 'nickname',
    numeric: false,
    label: 'NICKNAME',
    sortable: true
  },
  {
    id: 'username',
    numeric: false,
    label: 'USERNAME',
    sortable: true
  },
  {
    id: 'filter',
    numeric: false,
    label: 'FILTER',
    sortable: true
  },
  {
    id: 'filter_type',
    numeric: false,
    label: 'FILTER_TYPE',
    sortable: true
  },
  {
    id: 'ban_state',
    numeric: false,
    label: 'STATUS',
    sortable: true
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Banlog) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
              : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



function App() {
  const [logs, setLogs] = useState<Banlog[]>([]);
  const [banLogId, setBanLogId] = useState("");
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [guildId, setGuildId] = useState("");
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState<keyof Banlog>('username');
  const [error, setError] = useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Banlog,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  useEffect(() => {
    setLoading(true);
    setBanLogId(window.location.pathname.split("/")[1]);

    fetch('/api/logs' + window.location.pathname + '/?page=' + page + '&sort=' + orderBy + '&order=' + order, {
      headers: {
        'Accept-Encoding': 'gzip',
      }
    })
      .then(res => res.json())
      .then((data: { count: number; logs: Banlog[] }) => {

        setLogs(data.logs);
        setCount(data.count);
        setLoading(false);
        setGuildId(data.logs[0].guild_id+"");
      }).catch(()=> setError(true));
  }, [page, orderBy, order]);

  // @ts-ignore
  return (
    <div className="App">
      <header className="Header">
        <img src="hashbot.png" className='logo'></img>
      </header>
      <body>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={error}
      >
        <Alert severity="error">Unable to find requested ban log!</Alert>
      </Backdrop>
        <div className='ban_info'>
          <div className='ban_info_line'>Log Id: {banLogId ? <code>{banLogId}</code> : <Skeleton variant="text" className='skeleton_val' />}</div>
          <div className='ban_info_line'>Guild Id: {guildId ? <code>{guildId}</code> : <Skeleton variant="text" className='skeleton_val' />}</div>
          <div className='ban_info_line'>Users Found: {count ? <code>{count}</code> : <Skeleton variant="text" className='skeleton_val' />}</div>
        </div>
        <TableContainer component={Paper}>
          <TablePagination
            rowsPerPageOptions={[1000]}
            component="div"
            count={count}
            rowsPerPage={1000}
            page={page - 1}
            onPageChange={(e, p) => { !loading && setPage(p + 1) }}
          />
          <Table sx={{ minWidth: 650 }}>
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {!loading ? logs.sort(getComparator(order, orderBy)).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.user_id}</TableCell>
                  <TableCell align="left">{row.nickname || ""}</TableCell>
                  <TableCell align="left">{row.username}</TableCell>
                  <TableCell align="left">{row.filter}</TableCell>
                  <TableCell align="left">{row.filter_type}</TableCell>
                  <TableCell align="left">{row.ban_state}</TableCell>
                </TableRow>
              )) :
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                  <TableCell align="left"><Skeleton variant="text" /></TableCell>
                </TableRow>}
            </TableBody>
          </Table>
          {!loading &&
            <TablePagination
              rowsPerPageOptions={[1000]}
              component="div"
              count={count}
              rowsPerPage={1000}
              page={page - 1}
              onPageChange={(e, p) => { setPage(p + 1) }}
            />
          }
        </TableContainer>
      </body>
    </div>
  );
}

export default App;
